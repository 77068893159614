import { IVideo } from "context";

interface SettingsProps {
  video: IVideo;
}

export function Settings({ video }: SettingsProps) {
  return (
    <pre>{JSON.stringify(video, null, 2)}</pre>
  );
}