import { useState } from 'react';
import cn from 'classnames';
import { supabase } from 'supabaseClient';

interface AvatarProps {
  url: string;
  size: number;
  onUpload: (url: string) => void;
}

export function Avatar({ url, size, onUpload }: AvatarProps) {
  const [uploading, setUploading] = useState(false);

  async function uploadAvatar(event: React.SyntheticEvent<HTMLInputElement>) {
    try {
      setUploading(true)

      if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }

      const file = event.currentTarget.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      let { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      onUpload(filePath);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div>
      <label htmlFor="avatar" className="block text-sm font-medium text-gray-700">
        Profile image
      </label>
      <div className="mt-1">
        <label htmlFor="avatar" className="relative cursor-pointer" style={{ height: size, width: size }}>
          {url ? (
            <img
              src={url}
              alt="Avatar"
              className="rounded-full"
              style={{ height: size, width: size }}
            />
          ) : (
            <div className="rounded-full bg-gray-200" style={{ height: size, width: size }} />
          )}
          <p className={cn('absolute text-white bg-black bg-opacity-40 rounded-full transition-opacity duration-200 inset-0 flex items-center justify-center', {
            'opacity-100': uploading,
            'opacity-0 hover:opacity-100': !uploading
          })}  style={{ height: size, width: size }}>{uploading ? 'Uploading ...' : 'Upload'}</p>
          <input
            className="hidden absolute"
            type="file"
            id="avatar"
            accept="image/*"
            onChange={uploadAvatar}
            disabled={uploading}
          />
        </label>
      </div>
    </div>
  )
}