import { useCallback, useContext, useState, createContext } from "react";
import type { ReactNode } from "react";
import type { Session } from "@supabase/supabase-js";
import { supabase } from "supabaseClient";
import { useEffect } from "react";

interface IUserProfile {
  createdAt: Date | null;
  updatedAt: Date | null;
  avatarUrl: string;
  firstName: string;
  lastName: string;
}

interface IUserContext {
  loading: boolean;
  session: Session | null;
  profile: IUserProfile;
  forcePasswordReset: boolean;
  updateProfile: (update: Partial<IUserProfile>) => void;
}

const defaultProfile = {
  createdAt: null,
  updatedAt: null,
  firstName: '',
  lastName: '',
  avatarUrl: '',
};

const defaultUserContext: IUserContext = {
  loading: true,
  session: null,
  profile: defaultProfile,
  forcePasswordReset: false,
  updateProfile: () => {},
};

export const UserContext = createContext<IUserContext>(defaultUserContext);

interface UserContextProviderProps {
  children: ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState<IUserProfile>(defaultProfile);
  const [forcePasswordReset, setForcePasswordReset] = useState(false);

  useEffect(() => {
    supabase.auth.refreshSession()
  }, []);

  useEffect(() => {
    if (document.location.hash !== "") {
      const hash = document.location.hash.substr(1);
      const parts = hash.split("&");
      const hasPasswordRecover = parts.includes("type=recovery");

      // for (const part of parts) {
      //     const [key, value] = part.split('=');
      // }

      if (hasPasswordRecover) {
        setForcePasswordReset(true);
      }
    }
  }, []);

  useEffect(() => {
    const { data, error } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (session) {
        setLoading(true);

        const { data, error } = await supabase
          .from('users')
          .select(`
            created_at,
            updated_at,
            avatar_url,
            first_name,
            last_name
          `)
          .eq(`id`, session!.user?.id);

        if (error) {
          console.error(error);
        }
        
        if (data && data.length === 1) {
          let avatarUrl = profile.avatarUrl;

          if (data[0].avatar_url !== '') {
            try {
              const { data: d, error } = await supabase.storage.from('avatars').download(data[0].avatar_url);
      
              if (error) {
                throw error
              }
      
              avatarUrl = URL.createObjectURL(d);
            } catch (error) {
              if (error instanceof Error) {
                console.log('Error downloading image: ', error.message)
              }
            }
          }

          setProfile({
            createdAt: data[0].created_at,
            updatedAt: data[0].updated_at,
            avatarUrl,
            firstName: data[0].first_name || '',
            lastName: data[0].last_name || '',
          });
        }
      } else {
        setProfile(defaultProfile);
      }

      setSession(session);
      setLoading(false);
    });

    if (error) {
      console.error(error);
    }

    if (data) {
      return data.unsubscribe;
    }
  }, []);

  const updateProfile = useCallback((update: Partial<IUserProfile>) => {
    setProfile(profile => ({
      ...profile,
      ...update,
    }));
  }, []);

  const userContextValue = {
    loading,
    session,
    profile,
    forcePasswordReset,
    updateProfile,
  };

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
