import { Loading } from 'components/loading';

export * from './404';
export * from './landing';
export * from './auth/sign-up';
export * from './auth/sign-in';
export * from './auth/sign-out';
export * from './auth/password-reset';
export * from './profile';

export function LoadingAfterLogin() {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 sm:px-6 lg:px-8">
      <Loading />
      <h1 className="ml-4 flex items-center text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-gold-100 to-gold-900">Loading</h1>
    </div>
  );
}