import cn from 'classnames';
import { IVideo, useVideoContext } from 'context';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PencilAltIcon, CheckIcon, XIcon } from '@heroicons/react/solid';
import { Button, HollowButton } from 'components/form/button';
import { ControlledInput } from 'components/form/input';
import { useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useEffect } from 'react';

interface IVideoHeaderForm {
  name: string;
}

const videoHeaderSchema = yup.object().shape({
  name: yup.string(),
});

interface HeaderProps {
  video: IVideo;
}

export function Header({ video }: HeaderProps) {
  const [isEditingName, setIsEditingName] = useState(false);
  const { url } = useRouteMatch();
  const location = useLocation();
  const { updateVideo } = useVideoContext();
  const { control, handleSubmit, formState, reset, setValue } = useForm<IVideoHeaderForm>({
    defaultValues: {
      name: video?.name || '',
    },
    mode: "onChange",
    resolver: yupResolver(videoHeaderSchema),
  });

  useEffect(() => setValue('name', video?.name || ''), [video]);

  if (video === null) {
    return null;
  }

  const onSubmit: SubmitHandler<IVideoHeaderForm> = async data => {
    if (formState.isValid) {
      updateVideo(data);
    }
  };

  const navigation = [
    { name: 'Editor',   to: url,               current: location.pathname === url },
    { name: 'Settings', to: `${url}/settings`, current: location.pathname === `${url}/settings` },
  ];

  console.log('TODO: "current-tab"');

  return (
    <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex items-center">
          <form className="flex items-center" onSubmit={handleSubmit(onSubmit)}>
            {isEditingName ? (
              <ControlledInput<IVideoHeaderForm>
                name="name"
                control={control}
                error={formState.submitCount > 0 ? formState.errors.name : undefined}
                placeholder="Video title"
                autoFocus
              />
            ) : (
              <h3 className="py-1.5 text-lg leading-6 font-medium text-gray-900">
                {video ? video.name || '(Untitled video)' : null}
              </h3>
            )}
            <HollowButton
              slim
              type="submit"
              title={isEditingName ? 'Save video name' : 'Edit video name'}
              className="ml-2 opacity-20 hover:opacity-100 transition-opacity group"
              onClick={() => {
                setIsEditingName(!isEditingName);
              }}
            >
              {isEditingName 
                ? <CheckIcon className="w-5 h-5 text-gray-500 group-hover:text-gold-500" />
                : <PencilAltIcon className="w-5 h-5 text-gray-500 group-hover:text-gold-500" />
              }
            </HollowButton>
            {isEditingName ? (
              <HollowButton
                type="reset"
                slim
                title="Cancel name change"
                className="ml-2 opacity-20 hover:opacity-100 transition-opacity group"
                onClick={() => setIsEditingName(false)}
              >
                <XIcon className="w-5 h-5 text-gray-500 group-hover:text-red-500" />
              </HollowButton>
            ) : null}
          </form>
        </div>
        <div className="mt-3 flex md:mt-0 md:absolute md:top-3 md:right-0 space-x-2">
          <HollowButton>Preview</HollowButton>
          <Button>Render</Button>
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold-500 focus:border-gold-500 sm:text-sm rounded-md"
            defaultValue={navigation.find((route) => route.current)?.name || 'Editor'}
          >
            {navigation.map((route) => (
              <option key={route.name}>{route.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {navigation.map((route) => (
              <Link
                key={route.name}
                to={route.to}
                className={cn(
                  route.current
                    ? 'border-gold-500 text-gold-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={route.current ? 'page' : undefined}
              >
                {route.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
