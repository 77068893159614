import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import {
  BellIcon,
  KeyIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';

export function SubNavigation() {
  const { url } = useRouteMatch();
  const location = useLocation();

  const subNavigation = [
    { name: 'Profile',       to: url,                   icon: UserCircleIcon, current: location.pathname === url },
    { name: 'Password',      to: `${url}/password`,     icon: KeyIcon,        current: location.pathname === `${url}/password` },
    { name: 'Notifications', to: `${url}/notifications`, icon: BellIcon,       current: location.pathname === `${url}/notifications` },
  ];

  return (
    <nav className="space-y-1">
      {subNavigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className={cn(
            item.current
              ? 'bg-white text-gold-600 hover:bg-white'
              : 'text-gray-900 hover:text-gold-900 hover:bg-gray-50',
            'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <item.icon
            className={cn(
              item.current ? 'text-gold-500' : 'text-gray-400 group-hover:text-gold-500',
              'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
            )}
            aria-hidden="true"
          />
          <span className="truncate">{item.name}</span>
        </Link>
      ))}
    </nav>
  )
}