import cn from 'classnames';

export * from './icon-button';
export * from './with-icon';
export * from './inline';
export * from './hollow';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  inline?: boolean;
}

export function Button({ inline = false, type = 'button', className, children = null, ...props }: ButtonProps) {
  return (
    <button
      type={type as any}
      {...props}
      className={cn('justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white', {
        'w-full flex': !inline,
        'inline-flex': inline,
        'bg-gold-600 hover:bg-gold-700': !props.disabled,
        'bg-gray-300': props.disabled,
      }, 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500', className)}
    >
      {children}
    </button>
  )
}
