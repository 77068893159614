import { useEffect } from 'react';
import { useNotificationContext } from 'context';
import { supabase } from 'supabaseClient';

export function useRealtime() {
  const { updateNotification, addNotification, removeNotification } = useNotificationContext();

  useEffect(() => {
    const mySubscription = supabase.from('*').on('*', ({ eventType, new: data, old, table }) => {
      // console.log({ eventType, data, old, table });
      switch (eventType) {
        case 'UPDATE':
          switch (table) {
            case 'notifications':
              const { id, ...update } = data;
              updateNotification(id, update);
              break;
          }
          break;
        case 'INSERT':
          switch (table) {
            case 'notifications':
              addNotification(data);
              break;
          }
          break;
        case 'DELETE':
          switch (table) {
            case 'notifications':
              removeNotification(old.id);
              break;
          }
          break;
      }
    })
    .subscribe();
    
    return () => {
      mySubscription.unsubscribe();
    };
  }, []);
}