import type { ReactNode } from 'react';
import cn from 'classnames';
import { Button, ButtonProps } from './index';

export interface ButtonWithIconProps extends ButtonProps {
  before?: ReactNode;
  after?: ReactNode;
}

export function ButtonWithIcon({ before = null, after = null, children = null, ...props }: ButtonWithIconProps) {
  return (
    <Button {...props}>
      {before}
      <span className={cn({
        'ml-4': before !== null,
        'mr-4': after !== null,
      })}>{children}</span>
      {after}
    </Button>
  )
}