import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import {
  Landing,
  SignIn,
  SignUp,
  SignOut,
  PasswordReset,
  NotFound,
  LoadingAfterLogin,
} from 'views';
import { DefaultLayout } from 'components/layout';
import { useUserContext } from "context";
import { VideoContextProvider, NotificationContextProvider } from 'context';
import { useRealtime } from 'hooks';

export function Routes() {
  const { session, loading } = useUserContext();

  console.log(loading, session);

  if (loading && session !== null) {
    return <LoadingAfterLogin />;
  }

  return (
    <Router>
      <RedirectToPasswordReset />
      {session === null ? <UnauthenticatedRoutes /> : <Authenticated />}
    </Router>
  )
}

const authenticatedRoutes = [
  '/video/:videoId',
  '/profile',
  '/profile/notifications'
];

function Authenticated() {
  return (
    <NotificationContextProvider>
      <VideoContextProvider>
        <AuthenticatedRoutes />
      </VideoContextProvider>
    </NotificationContextProvider>
  )
}

function AuthenticatedRoutes() {
  useRealtime();
  
  return (
    <Switch>
      <Route exact path="/"><DefaultLayout /></Route>
      {authenticatedRoutes.map(route => <Route key={route} path={route}><DefaultLayout /></Route>)}
      
      {/* Auth routes */}
      <Route path="/sign-out"><SignOut /></Route>

      {/* Redirect unauthenticated paths */}
      <Route path="/sign-up"><Redirect to="/" /></Route>
      <Route path="/sign-in"><Redirect to="/" /></Route>

      <Route path="*"><NotFound /></Route>
    </Switch>
  )
}

function UnauthenticatedRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/sign-in">
        <SignIn />
      </Route>
      <Route path="/password-reset">
        <PasswordReset />
      </Route>

      {/* Redirect authenticated paths */}
      <Route path="/sign-out"><Redirect to="/sign-in" /></Route>
      {authenticatedRoutes.map(route => <Route key={route} path={route}><Redirect to="/sign-in" /></Route>)}

      <Route path="*"><NotFound /></Route>
    </Switch>
  )
}

function RedirectToPasswordReset() {
  const { forcePasswordReset } = useUserContext();
  const location = useLocation();

  if (forcePasswordReset && location.pathname !== '/profile') {
    return <Redirect to="/profile" push={true} />;
  }

  return null;
}