import { useCallback, useEffect } from 'react';
import { LabelledInput } from 'components/form/input';
import { ButtonWithIcon } from 'components/form/button';
import { useUserContext } from 'context/user';
import { useFormValue } from 'context/form';
import { supabase } from 'supabaseClient';
import { LoadingIcon } from 'components/icon';
import { Avatar } from './avatar';

export function ProfileForm() {
  const { session, profile, updateProfile } = useUserContext();
  const initialFormValues = {
    firstName: profile.firstName,
    lastName: profile.lastName,
  };
  const [formState, setFormState] = useFormValue('profile-form', { pending: false, error: null, success: false });
  const [form, setForm] = useFormValue('profile', initialFormValues);

  // Reset the form on unmount
  useEffect(() => {
    return () => {
      setFormState({ pending: false, error: null, success: false });
      setForm({ initialFormValues: '' });
    }
  }, []); // eslint-disable-line

  const handleSubmit = useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormState({ pending: true, error: null, success: false });

    // const { error } = await supabase.auth.api
    //   .updateUser(session!.access_token, { password: form.password })

    const { data, error } = await supabase.from('users').upsert({
      id: session!.user?.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      first_name: form.firstName,
      last_name: form.lastName,
    });

    if (error) {
      setFormState({ pending: false, error, success: false });
    } else {
      if (data && data.length === 1) {
        updateProfile({
          firstName: form.firstName,
          lastName: form.lastName,
        });
      }
      setFormState({ pending: false, error: null, success: true });
      setForm({ password: '' });
    }
  }, [form, session, updateProfile]);

  const handleAvatarUpload = useCallback(async (avatarUrl: string) => {
    const { error } = await supabase.from('users').upsert({
      id: session!.user?.id,
      avatar_url: avatarUrl,
    });

    if (error) {
      console.error(error);
    } else {
      try {
        const { data, error } = await supabase.storage.from('avatars').download(avatarUrl);

        if (error) {
          throw error
        }

        const url = URL.createObjectURL(data);
        updateProfile({ avatarUrl: url });
      } catch (error) {
        console.log('Error downloading image: ', error.message)
      }
    }
  }, [session, updateProfile]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <section aria-labelledby="profile-heading">
        <form onSubmit={handleSubmit}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 id="profile-heading" className="text-lg leading-6 font-medium text-gray-900">
                  Profile
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Update your profile information
                </p>
              </div>

              <div className="mt-6 grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-2 space-y-4">
                  {/* <LabelledInput
                    label="First name"
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="cc-given-name"
                    value={form.firstName}
                    onChange={(e) => setForm({ firstName: e.currentTarget.value })}
                    disabled={formState.pending}
                  />

                  <LabelledInput
                    label="Last name"
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="cc-family-name"
                    value={form.lastName}
                    onChange={(e) => setForm({ lastName: e.currentTarget.value })}
                    disabled={formState.pending}
                  /> */}
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <Avatar
                    url={profile.avatarUrl}
                    size={150}
                    onUpload={handleAvatarUpload}
                  />
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <ButtonWithIcon inline={true} before={formState.pending ? <LoadingIcon color="white" /> : null} type="submit" disabled={formState.pending}>
                Save
              </ButtonWithIcon>
            </div>
          </div>
        </form>
      </section>

    </div>
  )
}
