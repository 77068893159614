import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';
import { LogoType } from 'assets/logo-type';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import LandingPreview from 'assets/landing-preview.png';

export function Landing() {
  return (
    <main className="flex flex-col min-h-screen md:flex-row">
      <div className="py-10 px-6 flex md:px-16 md:justify-end md:items-center md:flex-grow">
        <div>
          <div title="Render chat">
            <LogoType className="h-10" />
          </div>
          <div className="mt-10 sm:mt-20 max-w-lg">
            {/* <div>
              <a href="/changelog" className="inline-flex space-x-4">
                <span className="rounded bg-gold-50 px-2.5 py-1 text-xs font-semibold text-gold-600 tracking-wide uppercase">
                  What's new
                </span>
                <span className="inline-flex items-center text-sm font-medium text-gold-500 space-x-1">
                  <span>Added Facebook messages theme</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </a>
            </div> */}
            <div className="mt-6 sm:max-w-xl">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Create your marketing chat video in minutes
              </h1>
              <p className="mt-6 text-xl text-gray-600">
                Save time and money by editing your own chat video with our browser based solution
              </p>
            </div>
            <div className="mt-12 sm:w-full sm:flex">
              <MailChimpSubscribe />
            </div>

          </div>
        </div>
      </div>

      <div className="py-10 px-6 bg-gold-50 flex justify-center md:px-16 md:justify-start md:items-center md:flex-grow">
        <img width="320" src={LandingPreview} alt="" />
      </div>
    </main>
  )
}

function AuthButtons() {
  return (
    <div className="mt-4 sm:mt-0 sm:ml-3 flex space-x-4">
      <Link
        to="/sign-up"
        className="text-center rounded-md border border-transparent px-5 py-3 bg-gold-500 text-base font-medium text-white shadow hover:bg-gold-600 focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-offset-2 sm:px-10"
      >
        Sign up
      </Link>
      <Link
        to="/sign-in"
        className="flex items-center text-sm font-medium text-gold-500 space-x-1"
      >
        Sign in
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </Link>
    </div>
  )
}

function MailChimpSubscribe() {
  const [email, setEmail] = useState('');
  const [_, set_] = useState('');
  
  return (
    <div className="border-t-2 border-gold-100 w-full max-w-lg">
      <h2 className="mt-8 text-3xl font-extrabold text-gold-500 tracking-tight sm:text-4xl">Get notified when we’re launching.</h2>
      <form
        action="https://chat.us5.list-manage.com/subscribe/post?u=1abba42e94ab469ab3a505630&amp;id=547cc61f6f"
        method="post"
        target="_blank"
        className="mt-8 flex flex-col items-center sm:space-x-4 sm:flex-row"
      >
        <Input
          type="email"
          value={email}
          name="EMAIL"
          placeholder="Your email address"
          onChange={e => setEmail(e.currentTarget.value)}
          style={{ minWidth: 320 }}
          required
        />
        <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
          <input type="text" name="b_1abba42e94ab469ab3a505630_547cc61f6f" tabIndex={-1} value={_} onChange={e => set_(e.currentTarget.value)} />
          <input type="hidden" value="Subscribe" name="subscribe" />
        </div>
        <Button className="mt-4 sm:mt-0" type="submit">Notify me</Button>
      </form>
    </div>
  )
}