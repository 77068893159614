import { Link as RRLink } from 'react-router-dom';
import type { LinkProps as RRLinkProps } from 'react-router-dom';
import cn from 'classnames';

export interface LinkProps extends RRLinkProps {
}

export function Link({ children = null, ...props}: LinkProps) {
  return (
    <RRLink
     {...props}
     className={cn('font-medium hover:text-gold-600 hover:underline', props.className)}
    >{children}</RRLink>
  )
}