import cn from 'classnames';
import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/solid'

export * from './withList';

export type AlertType =
  'SUCCESS' |
  'WARNING' |
  'ERROR' |
  'INFO';

const iconMap = {
  SUCCESS: CheckCircleIcon,
  WARNING: ExclamationIcon,
  ERROR: XCircleIcon,
  INFO: InformationCircleIcon,
};

const typeColorMap = {
  SUCCESS: 'green',
  WARNING: 'yellow',
  ERROR: 'red',
  INFO: 'blue',
}

export interface AlertProps extends React.HTMLProps<HTMLElement> {
  type: AlertType;
  title: string;
  dismiss?: () => void;
}

export function Alert({ ...props }: AlertProps) {
  const Icon = iconMap[props.type];
  const color = typeColorMap[props.type];

  return (
    <div className={cn('rounded-md p-4', {
      'bg-green-50':  props.type === 'SUCCESS',
      'bg-yellow-50': props.type === 'WARNING',
      'bg-red-50':    props.type === 'ERROR',
      'bg-blue-50':   props.type === 'INFO',
    }, props.className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={cn('h-5 w-5', {
            'text-green-400':  props.type === 'SUCCESS',
            'text-yellow-400': props.type === 'WARNING',
            'text-red-400':    props.type === 'ERROR',
            'text-blue-400':   props.type === 'INFO',
          })} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={cn('text-sm font-medium', {
            'text-green-800':  props.type === 'SUCCESS',
            'text-yellow-800': props.type === 'WARNING',
            'text-red-800':    props.type === 'ERROR',
            'text-blue-800':   props.type === 'INFO',
          })}>{props.title}</h3>
          {props.children ? (
            <div className={cn('mt-2 text-sm', {
              'text-green-700':  props.type === 'SUCCESS',
              'text-yellow-700': props.type === 'WARNING',
              'text-red-700':    props.type === 'ERROR',
              'text-blue-700':   props.type === 'INFO',
            })}>
              {props.children}
            </div>
          ) : null}
        </div>
        {props.dismiss ? (
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={cn(`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2`, {
                'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600':      props.type === 'SUCCESS',
                'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600': props.type === 'WARNING',
                'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600':                props.type === 'ERROR',
                'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600':           props.type === 'INFO',
              })}
              onClick={props.dismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        ) : null}
      </div>
    </div>
  )
}
