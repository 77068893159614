import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { Profile } from 'views';
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Navigation } from './navigation';
import { Notification } from './notification';
import { User } from "./user";
import { LogoType } from 'assets/logo-type';
import { Videos } from 'views/videos';
import { Video } from 'views/video';

export function DefaultLayout() {
  const location = useLocation();

  console.log(location.pathname);

  const navigation = [
    { name: 'Videos', to: '/',  current: location.pathname === '/' || location.pathname.startsWith('/video/') },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Disclosure as="nav" className="bg-white shadow-sm z-10">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <Logo />

                  <Navigation.Desktop navigation={navigation} />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <Notification.Desktop />

                  <User.Desktop />
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <MobileMenuButton open={open} />
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <Navigation.Mobile navigation={navigation} />

              <User.Mobile />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="py-10 flex-grow">
        <main className="">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <Switch>
              <Route exact path="/"><Videos /></Route>
              <Route exact path="/video"><Redirect to="/" /></Route>
              <Route path="/video/:videoId"><Video /></Route>
              <Route path="/profile"><Profile /></Route>
            </Switch>
          </div>
        </main>
      </div>
    </div>
  )
}

function Logo() {
  return (
    <div className="flex-shrink-0 flex items-center">
      <LogoType className="h-6" />
    </div>
  )
}

function MobileMenuButton({ open }: { open: boolean }) {
  return (
    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <XIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  )
}