import { Link } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/outline'
import cn from 'classnames';
import { useNotificationContext } from 'context';
import type { INotification } from 'context';

export const Notification = {
  Desktop: () => {
    const { notifications } = useNotificationContext();
    const unread = notifications.filter(filterUnreadNotifications);

    return (
      <Link to="/profile/notifications" className={cn('p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500', {
        'text-gray-400 hover:text-gray-500': unread.length === 0,
        'text-gold-400 hover:text-gold-500 hover:bg-gold-50': unread.length > 0,
      })}>
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </Link>
    )
  },
  Mobile: () => (
    <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <span className="sr-only">View notifications</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  ),
};

function filterUnreadNotifications(notification: INotification) {
  return notification.read !== true;
}