import { useCallback, useEffect, useContext, useState, createContext } from 'react';
import type { ReactNode } from 'react';
import { supabase } from 'supabaseClient';

type NotificationType = 'event';
type NotificationTemplate = string;
type NotificationData = {
  [key: string]: any;
};

export interface INotification {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  read: boolean;
  type: NotificationType;
  template: NotificationTemplate;
  data: NotificationData;
}

interface INotificationContext {
  loading: boolean;
  notifications: INotification[];
  addNotification(notification: INotification): void;
  removeNotification(id: string): void;
  updateNotification(id: string, update: Omit<INotification, 'id'>): void;
  markNotificationAsRead(id: string): void;
}

const defaultNotificationContext: INotificationContext = {
  loading: true,
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
  updateNotification: () => {},
  markNotificationAsRead: () => {},
};

export const NotificationContext = createContext<INotificationContext>(defaultNotificationContext);

interface NotificationContextProviderProps {
  children: ReactNode;
}

export function NotificationContextProvider({ children }: NotificationContextProviderProps) {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    async function getData() {
      const { data, error } = await supabase.from('notifications').select('*').order('created_at', { ascending: true });

      if (error) {
        console.error(error);
        return;
      }

      console.log({ data });

      setNotifications(data?.map(mapDbNotification) as INotification[]);
    }

    getData();
  }, []);

  const addNotification = useCallback((notification: INotification) => {
    setNotifications(notifications => [...notifications, mapDbNotification(notification)]);
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications(notifications => notifications.filter(notification => notification.id !== id));
  }, []);

  const updateNotification = useCallback((id: string, update: Partial<Omit<INotification, 'id'>>) => {
    // @ts-ignore -- Making sure we don't update the id
    const { id: notificationId, ...updateWithoutId } = update;
    setNotifications(notifications => notifications.map(n => n.id === id ? { ...n, ...updateWithoutId } : n));
  }, []);

  const markNotificationAsRead = useCallback(async (id: string) => {
    const { data, error } = await supabase.from('notifications').update({ read: true }).match({ id });
    if (error) {
      console.error(error);
      return;
    }

    if (data) {
      updateNotification(id, { read: true });
    }
  }, []);

  const notificationContextValue = {
    loading,
    notifications,
    addNotification,
    removeNotification,
    updateNotification,
    markNotificationAsRead,
  };

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export function mapDbNotification(n: any) {
  return {
    ...n,
    createdAt: n.createdAt || new Date(n.created_at),
    updatedAt: n.updatedAt || new Date(n.updated_at),
  }
}