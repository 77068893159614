import { useState } from 'react';
import { supabase } from 'supabaseClient';
import { LabelledInput } from 'components/form/input';
import { ButtonWithIcon } from 'components/form/button';
import { LoadingIcon } from 'components/icon';
import type { AlertType } from 'components/alert';
import { AuthForm } from 'components/landing';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

interface IPasswordResetForm {
  email: string;
}

const passwordResetSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export function PasswordReset() {
  const [supabaseError, setSupabaseError] = useState<Error | null>(null);
  const { control, handleSubmit, formState } = useForm<IPasswordResetForm>({
    defaultValues: {
      email: '',
    },
    mode: "onChange",
    resolver: yupResolver(passwordResetSchema),
  });

  const onSubmit: SubmitHandler<IPasswordResetForm> = async data => {
    setSupabaseError(null);
    const { error } = await supabase.auth.api.resetPasswordForEmail(data.email);
    
    if (error) {
      setSupabaseError(error);
    }
  }

  const alert = supabaseError ? {
    type: 'ERROR' as AlertType,
    title: 'Error',
    message: [supabaseError.message],
  } : formState.isSubmitted && !formState.isSubmitting ? {
    type: 'SUCCESS' as AlertType,
    title: 'Reset email sent',
    message: ['We have sent a password reset email to the provided email address'],
  } : formState.submitCount > 0 && Object.entries(formState.errors).length > 0 ? {
    type: 'ERROR' as AlertType,
    title: 'Error',
    message: Object.values(formState.errors).map(error => error.message) as string[],
  } : undefined;
  
  return (
    <AuthForm title="Reset your password" alert={alert}>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <LabelledInput<IPasswordResetForm>
          control={control}
          label="Email address"
          name="email"
          type="email"
          error={formState.submitCount > 0 ? formState.errors.email : undefined}
          disabled={formState.isSubmitting}
          required
        />

        <div>
          <ButtonWithIcon type="submit" disabled={formState.isSubmitting} after={formState.isSubmitting ? <LoadingIcon color="gold" /> : null}>
            Request password reset
          </ButtonWithIcon>
        </div>
      </form>
    </AuthForm>
  )
}
