import { Link } from 'react-router-dom';
import { Button, HollowButton } from 'components/form/button';

export function NotFound() {
  function handleHistoryBack(event: any) {
    event.preventDefault();
    window.history.back();
  }
  
  return (
    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-gold-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-gold-600 to-black">Page not found</h1>
              <p className="mt-1 text-gold-900">Please check the URL in the address bar and try again.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button
                onClick={handleHistoryBack}
                inline={true}
              >
                Go to previous page
              </Button>
              <Link to="/">
                <Button inline={true}>
                  Go to homepage
                </Button>
              </Link>
              <a href="mailto:help@example.com">
                <HollowButton>
                  Contact support
                </HollowButton>
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
