import { useCallback, useContext, useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import type { ReactNode } from "react";
import { supabase } from "supabaseClient";
import { useEffect } from "react";
import { useUserContext } from 'context';

type VideoTheme = 'default' | 'whatsapp';
type VideoMessageAppearance = 'alternating' | 'dedicated';
type MessageType = 'message' | 'meta';
type Owner = string;

export interface IMessage {
  id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  video_id: string;
  index: number;
  delay: number;
  type: MessageType;
  sender: string;
  message: string;
  giphy: string;
}

export interface IVideo {
  id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  name: string;
  theme: VideoTheme;
  appearance: VideoMessageAppearance;
  owner: Owner; // The message receiver
  start_frames: number;
  end_frames: number;
  play_sounds: boolean;
  show_title: boolean;
  show_input: boolean;
  messages: IMessage[];
}

export interface IVideoListItem extends Pick<IVideo, 'id' | 'updated_at' | 'name' | 'theme'> {
}

interface IVideoContext {
  loading: boolean;
  video: IVideo | null;
  videos: IVideoListItem[];
  getVideo: (id: string) => void;
  getVideos: () => void;
  updateVideo: (update: Partial<Omit<IVideo, 'id' | 'userId'>>) => void;
  createVideo: () => Promise<string>;
  updateMessage: (id: string, update: Partial<Omit<IMessage, 'id' | 'userId'>>) => void;
  createMessage: (message: Omit<IMessage, 'id' | 'created_at' | 'updated_at'>) => Promise<string>;
}

const defaultVideoContext: IVideoContext = {
  loading: true,
  video: null,
  videos: [],
  getVideo: () => {},
  getVideos: () => {},
  updateVideo: () => {},
  createVideo: () => Promise.resolve(''),
  updateMessage: () => {},
  createMessage: () => Promise.resolve(''),
};

export const VideoContext = createContext<IVideoContext>(defaultVideoContext);

interface VideoContextProviderProps {
  children: ReactNode;
}

export function VideoContextProvider({ children }: VideoContextProviderProps) {
  const history = useHistory();
  const { session } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState<IVideo | null>(null);
  const [videos, setVideos] = useState<IVideoListItem[]>([]);

  const getVideo = useCallback(async (id: string) => {
    setLoading(true);
    const { data, error } = await supabase.from('videos').select(`*`).eq('id', id);

    if (error) {
      console.error(error);
      setVideo(null);
      setLoading(false);
      return;
    }

    if (data) {
      const video = data[0] as IVideo;
      const { data: messageData, error } = await supabase.from('messages').select(`*`).eq('video_id', video.id);

      if (error) {
        console.error(error);
      }

      if (messageData) {
        video.messages = messageData;
      }

      setVideo(video as IVideo);
    }

    setLoading(false);
  }, []);

  const getVideos = useCallback(async () => {
    const { data, error } = await supabase.from('videos').select(`
      id,
      created_at,
      updated_at,
      name,
      theme
    `);

    if (error) {
      console.error(error);
    }

    setVideos(data as IVideoListItem[]);
    setLoading(false);
  }, []);

  // Load videos list
  useEffect(() => {
    getVideos();
  }, []);

  const updateVideo = useCallback(async (update: Partial<Omit<IVideo, 'id' | 'userId'>>, localOnly: boolean = false) => {
    if (video) {
      setVideo(video => video ? ({
        ...video,
        ...update,
      }) : video);

      if (!localOnly) {
        delete update.messages;
        const { error } = await supabase.from('videos').update(update).eq('id', video.id);

        if (error) {
          console.error(error);
        }
      }
    }
  }, [video]);

  const updateMessage = useCallback(async (id: string, update: Partial<Omit<IMessage, 'id' | 'userId'>>) => {
    if (id) {
      setVideo(video => video ? ({
        ...video,
        messages: video.messages.map(message => message.id === id ? ({
          ...message,
          ...update,
        }) : message),
      }) : video);
      const { error } = await supabase.from('videos').update(update).eq('id', id);

      if (error) {
        console.error(error);
      }
    }
  }, []);

  const createVideo = useCallback((): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const { data, error } = await supabase.from('videos').insert([{
        name: '',
        user_id: session?.user?.id,
        theme: 'default',
        appearance: 'alternating',
        owner: '',
        start_frames: 0,
        end_frames: 0,
        play_sounds: true,
        show_title: true,
        show_input: true,
      }]);

      if (error) {
        console.error(error);
        reject(error);
      }

      if (data) {
        resolve(data[0].id);

        history.push(`/video/${data[0].id}`);
        
        getVideos();
      }
    });
  }, []);

  const createMessage = useCallback((message: Omit<IMessage, 'id' | 'created_at' | 'updated_at'>): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const { data, error } = await supabase.from<IMessage>('messages').insert([message]);

      if (error) {
        console.error(error);
        reject(error);
      }

      if (data) {
        resolve(data[0].id);

        const messages = [...(video?.messages || []), data[0]];

        updateVideo({ messages }, true);
      }
    });
  }, [video]);

  const videoContextValue = {
    loading,
    video,
    videos,
    getVideo,
    getVideos,
    updateVideo,
    createVideo,
    updateMessage,
    createMessage,
  };

  return (
    <VideoContext.Provider value={videoContextValue}>
      {children}
    </VideoContext.Provider>
  );
}

export function useVideoContext() {
  return useContext(VideoContext);
}