import { useState } from 'react';
import { supabase } from 'supabaseClient';
import { Link } from 'components/common';
import { useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { LabelledInput, PasswordInput } from 'components/form/input';
import { ButtonWithIcon } from 'components/form/button';
import { AlertType } from 'components/alert';
import { LoadingIcon } from 'components/icon';
import { AuthForm, ThirdPartyAuthButtons } from 'components/landing';

interface ISingInForm {
  email: string;
  password: string;
}

const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
});

export function SignIn() {
  const location = useLocation<{ fromSignUp: boolean, email: string }>();
  const [supabaseError, setSupabaseError] = useState<Error | null>(null);
  const { control, handleSubmit, formState, setValue } = useForm<ISingInForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: "onChange",
    resolver: yupResolver(signInSchema),
  });

  const onSubmit: SubmitHandler<ISingInForm> = async data => {
    const { error } = await supabase.auth.signIn(data);
    
    if (error) {
      setSupabaseError(error);
    }
  };

  let alert;
  
  if (supabaseError) {
    alert = {
      type: 'ERROR' as AlertType,
      title: 'Error',
      message: [supabaseError.message],
    };
  } else if (location.state && location.state.fromSignUp) {
    if (location.state.email) {
      setValue('email', location.state.email);
    }

    alert = {
      type: 'INFO' as AlertType,
      title: 'Verify your email',
      message: ['Please check your email for a verification link.'],
    };
  }

  return (
    <AuthForm title="Sign in to your account" alert={alert}>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <LabelledInput<ISingInForm>
          control={control}
          label="Email address"
          name="email"
          error={formState.submitCount > 0 ? formState.errors.email : undefined}
          required
        />

        <PasswordInput<ISingInForm>
          control={control}
          label="Password"
          name="password"
          error={formState.submitCount > 0 ? formState.errors.password : undefined}
          required
        />

        <div className="flex items-center justify-end">
          <div className="text-sm">
            <Link to="/password-reset">
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <ButtonWithIcon type="submit" disabled={formState.isSubmitting} after={formState.isSubmitting ? <LoadingIcon color="gray" /> : null}>
            Sign in
          </ButtonWithIcon>
        </div>
      </form>

      <ThirdPartyAuthButtons disabled={formState.isSubmitting} setSupabaseError={setSupabaseError} />
    </AuthForm>
  )
}
