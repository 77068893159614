import { useState } from 'react';
import { supabase } from 'supabaseClient';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { LabelledInput, PasswordInput } from 'components/form/input';
import { ButtonWithIcon } from 'components/form/button';
import { LoadingIcon } from 'components/icon';
import { AlertType } from 'components/alert';
import { AuthForm, ThirdPartyAuthButtons } from 'components/landing';

interface ISingUpForm {
  email: string;
  password: string;
}

const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
});

export function SignUp() {
  const history = useHistory();
  const [supabaseError, setSupabaseError] = useState<Error | null>(null);
  const { control, handleSubmit, formState, setError,  } = useForm<ISingUpForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: "onChange",
    resolver: yupResolver(signUpSchema),
  });

  const onSubmit: SubmitHandler<ISingUpForm> = async data => {
    const { error } = await supabase.auth.signUp(data);
    
    if (error) {
      setSupabaseError(error);
    } else {
      const location = {
        pathname: '/sign-in',
        state: {
          fromSignUp: true,
          email: data.email,
        }
      }
      
      history.push(location);
    }
  };

  const alert = supabaseError ? {
    type: 'ERROR' as AlertType,
    title: 'Error',
    message: [supabaseError.message],
  } : undefined;
  
  return (
    <AuthForm title="Sign up for an account" alert={alert}>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <LabelledInput<ISingUpForm>
          control={control}
          label="Email address"
          name="email"
          error={formState.submitCount > 0 ? formState.errors.email : undefined}
          required
        />

        <PasswordInput<ISingUpForm>
          control={control}
          label="Password"
          name="password"
          error={formState.submitCount > 0 ? formState.errors.password : undefined}
          required
        />
        <div>
          <ButtonWithIcon type="submit" disabled={formState.isSubmitting} after={formState.isSubmitting ? <LoadingIcon color="gray" /> : null}>
            Sign up
          </ButtonWithIcon>
        </div>
      </form>

      <ThirdPartyAuthButtons disabled={formState.isSubmitting} setSupabaseError={setSupabaseError} />
    </AuthForm>
  )
}
