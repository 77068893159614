import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useVideoContext } from 'context';
import { useEffect } from 'react';
import { Header } from './header';
import { Editor } from './editor';
import { Settings } from './settings';

export function Video() {
  const { videoId } = useParams<{ videoId: string }>();
  const { loading, video, getVideo } = useVideoContext();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!loading && videoId && (video === null || video.id !== videoId)) {
      getVideo(videoId);
    }
  }, [loading, videoId, video]);

  if (loading || video === null) {
    return null;
  }

  return (
    <>
      <Header video={video} />
      <Switch>
        <Route exact path={path}><Editor video={video} /></Route>
        <Route exact path={`${path}/settings`}><Settings video={video} /></Route>

        <Route path="*"><Redirect to={path} /></Route>
      </Switch>
    </>
  )
}