import { useEffect } from 'react';
import { useFormValue } from 'context';
import { supabase } from 'supabaseClient';
import { Alert } from 'components/alert';
import { Loading } from 'components/loading';

export function SignOut() {
  const [formState, setFormState] = useFormValue('sign-out-form', { error: null });

  useEffect(() => {
    async function signOut() {
      try {
        const { error } = await supabase.auth.signOut();

        if (error) {
          setFormState({ error });
          return;
        }
      } catch (error) {
        setFormState({ error });
      }
    }

    signOut();
  }, []); // eslint-disable-line

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Signing out</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {formState.error ? (
          <div className="mb-4">
            <Alert
              title="Error"
              type="ERROR"
              className="shadow"
            >
              {formState.error.message}
            </Alert>
          </div>
        ) : <Loading />}
      </div>
    </div>
  )
}
