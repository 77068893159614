import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { ControlledInput } from './index';
import type { ControlledInputProps } from './index';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

export interface PasswordInputProps<T> extends ControlledInputProps<T> {
  label: string;
}

export function PasswordInput<T>({ label, type, id = uuid(), ...props }: PasswordInputProps<T>) {
  const [showValue, setShowValue] = useState(false);

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={cn('mt-1 relative', {
        'rounded-md shadow-sm': typeof props.error !== 'undefined',
      })}>
        <ControlledInput<T>
          id={id}
          type={showValue ? 'text' : 'password'}
          className="pr-10"
          {...props}
        />
        <button
          type="button" onClick={() => setShowValue(!showValue)}
          style={{ height: 'calc(100% - 2px)', borderRadius: 6, right: props.error ? 40 : 0 }}
          className="absolute top-px right-px px-3 flex items-center text-gold-200 hover:text-gold-500"
          aria-label={showValue ? 'Hide field value' : 'Show field value'}
        >
          {showValue ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
        </button>
      </div>
      {props.error && (
        <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
          {props.error.message}
        </p>
      )}
    </div>
  )
}