import ReactDOM from 'react-dom';
import { UserContextProvider, FormContextProvider } from 'context';
import { Routes } from 'routes';
import './index.css';

ReactDOM.render(
  <UserContextProvider>
    <FormContextProvider>
      <Routes />
    </FormContextProvider>
  </UserContextProvider>,
  document.getElementById('root')
);
