import './loading.css';

export function Loading() {
  return (
    <svg className="w-12 h-12 loading" viewBox="0 0 68 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="16" rx="4" fill="#CCA300" />
      <rect x="20" y="20" width="48" height="16" rx="4" fill="#E3BE02"/>
      <rect y="40" width="48" height="16" rx="4" fill="#CCA300"/>
    </svg>
  )
}
