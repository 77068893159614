import { IVideo, useUserContext } from 'context';
import { useVideoContext } from 'context';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { LabelledInput } from 'components/form/input';

interface EditorProps {
  video: IVideo;
}

interface IMessageForm {
  sender: string;
  message: string;
}

const messageSchema = yup.object().shape({
  sender: yup.string().required(),
  message: yup.string().required(),
});


// {
//   "id": "72b228e1-e346-4ce7-9360-980d037ae5d3",
//   "created_at": "2021-08-17T17:58:49.062803+00:00",
//   "updated_at": "2021-08-17T17:58:49.062803+00:00",
//   "user_id": "09ebe419-df69-4bf0-b6d5-6242caaec90e",
//   "name": "Muutto",
//   "owner": "",
//   "theme": "default",
//   "appearance": "alternating",
//   "start_frames": 0,
//   "end_frames": 0,
//   "play_sounds": true,
//   "show_title": true,
//   "show_input": true,
//   "createdAt": "2021-08-17T17:58:49.062Z",
//   "updatedAt": "2021-08-17T17:58:49.062Z"
// }

export function Editor({ video }: EditorProps) {
  const { session } = useUserContext();
  const { createMessage } = useVideoContext();
  const { control, handleSubmit, formState } = useForm<IMessageForm>({
    defaultValues: {
      sender: '',
      message: '',
    },
    mode: "onChange",
    resolver: yupResolver(messageSchema),
  });

  const onSubmit: SubmitHandler<IMessageForm> = async data => {
    console.log({ formState });
    if (formState.isValid) {
      createMessage({
        sender: data.sender,
        message: data.message,
        delay: 0,
        giphy: '',
        index: video.messages.length,
        type: 'message',
        user_id: session?.user?.id || video.user_id,
        video_id: video.id,
      });
    }
  };

  return (
    <div className="mt-4 flex">
      <div className="bg-blue-100 w-96">
        {video.messages.map(message => (
          <div key={message.id}>{message.sender}: {message.message}</div>
        ))}
      </div>
      <div className="flex-grow bg-red-100">
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelledInput<IMessageForm>
            label="Message"
            name="message"
            control={control}
            error={formState.submitCount > 0 ? formState.errors.message : undefined}
            autoFocus
          />
          <LabelledInput<IMessageForm>
            label="Sender"
            name="sender"
            control={control}
            error={formState.submitCount > 0 ? formState.errors.sender : undefined}
          />
          <button type="submit">add</button>
        </form>
      </div>
    </div>
  );
}