import { Link } from 'components/common';
import { Alert, AlertType } from 'components/alert';
import { Logo } from 'assets/logo';

export interface AuthFormAlert {
  type: AlertType;
  title: string;
  message: string[];
};

interface AuthFormProps {
  title: string;
  alert?: AuthFormAlert,
  children: React.ReactNode;
}

export function AuthForm({ title, alert, children }: AuthFormProps) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="w-12 h-12 mx-auto" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gold-600">{title}</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {alert ? (
          <div className="mb-4">
            <Alert title={alert.title} type={alert.type} className="shadow">
              {alert.message.map(message => <ErrorMessage key={message}>{message}</ErrorMessage>)}
            </Alert>
          </div>
        ) : null}

        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {children}
        </div>
        <nav className="mt-4 flex justify-center space-x-4">
          <Link to="/">Home</Link>
          <span>•</span>
          <Link to="/sign-up">Sign up</Link>
          <span>•</span>
          <Link to="/sign-in">Sign in</Link>
        </nav>
      </div>
    </div>
  )
}

function ErrorMessage(props: { children: React.ReactChild }) {
  return (
    <div>
      {props.children}
    </div>
  )
}