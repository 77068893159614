import { v4 as uuid } from "uuid";
import { useCallback, useContext, useState, createContext } from "react";
import type { ReactNode } from "react";
import { useEffect } from "react";

interface FormContent {
  [key: string]: any;
}

interface IFormContext {
  form: FormContent;
  setForm: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

const defaultFormContext: IFormContext = {
  form: {},
  setForm: () => {},
};

export const FormContext = createContext<IFormContext>(defaultFormContext);

interface FormContextProviderProps {
  children: ReactNode;
}

export function FormContextProvider({ children }: FormContextProviderProps) {
  const [form, setForm] = useState<{ [key: string]: any }>({});

  const sessionContextValue = {
    form,
    setForm,
  };

  return (
    <FormContext.Provider value={sessionContextValue}>
      {children}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext);
}

export function useFormValue(
  formName: string = uuid(),
  defaultValue: FormContent = {},
  updateValues: FormContent = {}
): [FormContent, React.Dispatch<React.SetStateAction<FormContent>>] {
  const { form, setForm } = useFormContext();
  const [value, setValue] = useState<FormContent>(
    form[formName] || defaultValue
  );

  const onChange = useCallback(
    (input: FormContent) => {
      const newValue = { ...value, ...input };
      setValue(newValue);
      setForm((form) => ({
        ...form,
        [formName]: newValue,
      }));
    },
    [setForm, formName, value]
  );

  // eslint-disable-next-line
  useEffect(() => onChange(updateValues), []);

  return [value, onChange];
}
