import { Input } from './index';
import type { InputProps } from './index';
import { useController } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";
export interface ControlledInputProps<T> extends UseControllerProps<T>, Omit<InputProps, 'name' | 'defaultValue'> {
    id?: string;
}

export function ControlledInput<T>(props: ControlledInputProps<T>) {
  const { field } = useController(props);
  const { ref, ...inputProps } = field;

  return <Input {...props} {...inputProps} inputRef={ref} />;
}
