import { Link } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import { useVideoContext } from 'context';
import type { IVideoListItem } from 'context';
import { Button } from 'components/form/button';
import { Empty } from './empty';

export function Videos() {
  const { loading, videos, createVideo } = useVideoContext();

  if (loading) {
    return null;
  }

  if (videos.length === 0) {
    return <Empty />;
  }

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Videos</h3>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <Button onClick={createVideo}>
            New video
          </Button>
        </div>
      </div>
      <ul className="mt-8 flex space-x-4">
        {videos.map(video => <VideoItem video={video} key={video.id} />)}
      </ul>
    </>
  );
}

interface IVideoItemProps {
  video: IVideoListItem;
}

function VideoItem({ video }: IVideoItemProps) {
  console.log(video);
  return (
    <Link to={`/video/${video.id}`} className="group">
      <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 transition-all group-hover:shadow-lg">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 text-sm font-medium truncate">{video.name || '(Untitled video)'}</h3>
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gold-800 text-xs font-medium bg-gold-50 rounded-full">
                {video.theme}
              </span>
            </div>
            <p className="mt-1 text-gray-500 text-sm truncate">{formatRelative(new Date(video.updated_at), new Date(), { weekStartsOn: 1 })}</p>
          </div>
        </div>
      </li>
    </Link>
  )
}
