import { PlusIcon } from '@heroicons/react/solid'
import { ButtonWithIcon } from 'components/form/button';
import { useVideoContext } from 'context';

export function Empty() {
  const { createVideo } = useVideoContext();

  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gold-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No videos</h3>
      <p className="mt-1 text-sm text-gray-600">Get started by creating your first video</p>
      <div className="mt-6">
        <ButtonWithIcon
          inline={true}
          before={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
          onClick={createVideo}
        >
          New video
        </ButtonWithIcon>
      </div>
    </div>
  )
}