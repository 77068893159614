import { useCallback, useEffect } from 'react';
import { supabase } from 'supabaseClient';
import { useFormValue } from 'context/form';
import { useUserContext } from 'context/user';
import { PasswordInput } from 'components/form/input';
import { ButtonWithIcon } from 'components/form/button';
import { LoadingIcon } from 'components/icon';
import { Alert } from 'components/alert';

export function Password() {
  const { session } = useUserContext();
  const [formState, setFormState] = useFormValue('password-form', { pending: false, error: null, success: false });
  const [form, setForm] = useFormValue('password', { password: '' });

  // Reset the form on unmount
  useEffect(() => {
    return () => {
      setFormState({ pending: false, error: null, success: false });
      setForm({ password: '' });
    }
  }, []); // eslint-disable-line

  const handleSubmit = useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormState({ pending: true, error: null, success: false });

    const { error } = await supabase.auth.api
      .updateUser(session!.access_token, { password: form.password })

    if (error) {
      setFormState({ pending: false, error, success: false });
    } else {
      setFormState({ pending: false, error: null, success: true });
      setForm({ password: '' });
    }
  }, [form.password, session]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <section aria-labelledby="payment-details-heading">
        {formState.error ? (
          <Alert
            title="Error"
            type="ERROR"
            className="shadow mb-4"
            dismiss={() => setFormState({ error: null })}
          >{formState.error.message}</Alert>
        ) : null}
        {formState.success ? (
          <Alert
            title="Password changed successfully"
            type="SUCCESS"
            className="shadow mb-4"
            dismiss={() => setFormState({ success: false })}
          />
        ) : null}
        <form onSubmit={handleSubmit}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 id="payment-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                  Password
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Change your password
                </p>
              </div>

              <div className="mt-6 grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-2">
                  {/* <PasswordInput
                    label="New password"
                    type="password"
                    autoComplete="none"
                    value={form.password}
                    onChange={event => setForm({ password: event.currentTarget.value })}
                    disabled={formState.pending}
                  /> */}
                </div>

                <div className="col-span-4 sm:col-span-2 flex flex-col">
                  <p className="mt-5 text-sm text-gray-500 flex-grow flex items-center">Please enter a password that's more than 6 characters.</p>
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <ButtonWithIcon inline={true} before={formState.pending ? <LoadingIcon color="white" /> : null} type="submit" disabled={formState.pending}>
                Save
              </ButtonWithIcon>
            </div>
          </div>
        </form>
      </section>

    </div>
  )
}
