import React from 'react';
import { Link } from "react-router-dom";
import cn from 'classnames';

interface NavigationItem {
  name: string;
  to: string;
  current: boolean;
}

export const Navigation = {
  Desktop: React.forwardRef<HTMLDivElement, { navigation: NavigationItem[] }>(({ navigation }, ref) => (
    <div ref={ref} className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
      {navigation.map((item) => {
        return (
          <Link
            key={item.name}
            to={item.to}
            className={cn(
              item.current
                ? 'border-gold-500 text-gray-900'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'inline-flex items-center pt-1 border-b-2 text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </Link>
        )
      })}
    </div>
  )),
  Mobile: ({ navigation }: { navigation: NavigationItem[] }) => (
    <div className="pt-2 pb-3 space-y-1">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className={cn(
            item.current
              ? 'bg-gold-50 border-gold-500 text-gold-700'
              : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          {item.name}
        </Link>
      ))}
    </div>
  )
}

