import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { ControlledInput } from './index';
import type { ControlledInputProps } from './index';

export interface LabelledInputProps<T> extends ControlledInputProps<T> {
  label: string;
}

export function LabelledInput<T>({ label, id = uuid(), ...inputProps }: LabelledInputProps<T>) {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={cn('mt-1 relative', {
        'rounded-md shadow-sm': typeof inputProps.error !== 'undefined',
      })}>
        <ControlledInput<T> id={id} {...inputProps} />
      </div>
      {inputProps.error && (
        <p className="mt-2 text-sm text-red-600" id={`${inputProps.name}-error`}>
          {inputProps.error.message}
        </p>
      )}
    </div>
  )
}