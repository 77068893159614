import type { Ref } from 'react';
import cn from 'classnames';
import type { FieldError } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export * from './labelled';
export * from './password';
export * from './controlled';

export interface InputProps extends React.HTMLProps<HTMLInputElement>, InputError {
  inputRef?: Ref<HTMLInputElement>;
}

export function Input({ inputRef = undefined, className, ...props }: InputProps) {
  return (
    <>
      <input
        ref={inputRef}
        {...props}
        className={cn('appearance-none block w-full px-3 py-2 border rounded-md shadow-sm', {
          'border-gray-300': !props.disabled,
          'border-gray-200 bg-gray-50': props.disabled,
          'placeholder-gray-400 focus:ring-gold-500 focus:border-gold-500': typeof props.error === 'undefined',
          'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': !props.disabled && typeof props.error !== 'undefined',
        },
        'sm:text-sm',
        'focus:outline-none')}
      />
      {props.error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
    </>
  )
}

export interface InputError {
  error?: FieldError;
}
