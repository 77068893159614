import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import cn from 'classnames';
import { Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/solid';
import { useUserContext } from 'context/user';
import { Notification } from './notification';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
  { name: 'Your Profile', to: '/profile' },
  { name: 'Sign out',     to: '/sign-out' },
];

export const User = {
  Desktop: DesktopUser,
  Mobile: MobileUser,
}

function DesktopUser() {
  const { profile } = useUserContext();

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">Open user menu</span>
              {profile.avatarUrl 
                ? <img className="h-8 w-8 rounded-full" src={profile.avatarUrl} alt="" />
                : <div className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center"><UserIcon className="w-5 h-5 text-white" /></div>}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <UserNavigation.Desktop navigation={userNavigation} />
          </Transition>
        </>
      )}
    </Menu>
  )
}

function MobileUser() {
  const { session, profile } = useUserContext();

  return (
    <div className="pt-4 pb-3 border-t border-gray-200">
      <div className="flex items-center px-4">
        <div className="flex-shrink-0">
          {profile.avatarUrl
            ? <img className="h-10 w-10 rounded-full" src={profile.avatarUrl} alt="" />
            : null}
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-gray-800">{profile.firstName} {profile.lastName}</div>
          <div className="text-sm font-medium text-gray-500">{session!.user?.email}</div>
        </div>
        <Notification.Mobile />
      </div>
      <UserNavigation.Mobile navigation={userNavigation} />
    </div>
  )
}

interface UserNavigationItem {
  name: string;
  to: string;
}

export const UserNavigation = {
  Desktop: React.forwardRef<HTMLDivElement, { navigation: UserNavigationItem[] }>(({ navigation }, ref) => (
    <Menu.Items
      ref={ref}
      static
      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      {navigation.map((item) => (
        <Menu.Item key={item.name}>
          {({ active }) => (
            <Link
              to={item.to}
              className={cn(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              {item.name}
            </Link>
          )}
        </Menu.Item>
      ))}
    </Menu.Items>
  )),
  Mobile: ({ navigation }: { navigation: UserNavigationItem[] }) => (
    <div className="mt-3 space-y-1">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
        >
          {item.name}
        </Link>
      ))}
    </div>
  )
}