import cn from 'classnames';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  slim?: boolean;
}

export function HollowButton({ type = 'button', slim = false, className, children = null, ...props }: ButtonProps) {
  return (
    <button
      className={cn('inline-flex items-center border border-transparent text-sm font-medium rounded-md text-gold-700 hover:text-gold-900 hover:bg-gold-100 hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500', {
        'px-2 py-1': slim,
        'px-4 py-2': !slim,
      }, className)}
      {...props}
    >
      {children}
    </button>
  )
}