import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { SubNavigation } from './sub-navigation';
import { ProfileForm } from './profile';
import { Password } from './password';
import { Notifications } from './notifications';

export function Profile() {
  const { path } = useRouteMatch();
  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <SubNavigation />
      </aside>

      <Switch>
        <Route exact path={path}><ProfileForm /></Route>
        <Route exact path={`${path}/password`}><Password /></Route>
        <Route exact path={`${path}/notifications`}><Notifications /></Route>

        <Route path="*"><Redirect to={path} /></Route>
      </Switch>
    </div>
  )
}
