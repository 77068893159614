import { RefreshIcon } from '@heroicons/react/solid';
import cn from 'classnames';

interface LoadingIconProps {
  color?: string;
}

export function LoadingIcon({ color = 'gray' }: LoadingIconProps) {
  return (
    <RefreshIcon className={cn('w-5 h-5 animate-reverse-spin', `text-${color}-500`)} />
  )
}