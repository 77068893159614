import cn from 'classnames';
import { format } from 'date-fns';
import { UserIcon } from '@heroicons/react/solid'
import { useNotificationContext } from 'context';
import type { INotification } from 'context';
import { useEffect } from 'react';

const typeMap = {
  event: {
    icon: UserIcon,
    color: 'bg-gray-400',
  }
};

export function Notifications() {
  const { notifications, markNotificationAsRead } = useNotificationContext();

  useEffect(() => {
    const timeout = setTimeout(() => {
      for (const notification of notifications.filter(n => n.read === false)) {
        markNotificationAsRead(notification.id);
      }
    }, 3000);

    return () => { clearTimeout(timeout); }
  }, [notifications]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div className="flow-root">
            <ul className="-mb-8">
              {notifications.map((notification, notificationIndex) => {
                const typeColor = typeMap[notification.type].color;
                const TypeIcon = typeMap[notification.type].icon;
                return (
                  <li key={notification.id}>
                    <div className="relative pb-8">
                      {notificationIndex !== notifications.length - 1 ? (
                        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={cn(
                              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white', {
                              [typeColor]: notification.read,
                              'bg-gold-400': !notification.read,
                            })}
                          >
                            <TypeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            {getNotificationContent(notification)}
                          </div>
                          <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <time dateTime={notification.createdAt.toISOString()}>{format(notification.createdAt, 'kk:mm dd.MM.yyyy')}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function getNotificationContent(notification: INotification) {
  switch (notification.template) {
    case 'new_user':
      return (
        <p className="text-sm text-gray-500">
          Welcome!
        </p>
      );
    default:
      return (
        <p className="text-sm text-gray-500">
          {JSON.stringify(notification, null, 2)}
        </p>
      );
  }
}